import React, { useState } from "react";

import { faSignOutAlt, faUpload } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../providers/authProvider";
import SideBarUser from "./SidebarUser";
import SidebarItem from "./SidebarItem";
import brand from "../../../assets/img/talladega.png";

import { authApi } from "../../../api/authServices";
import SidebarMenu from "./SidebarMenu";
import { adminRoutes } from "../../../routes";
import { useSidebar } from "../../../providers/sidebarProvider";
import UploadEmployeeUtilizationModal from "../UploadEmployeeUtilizationModal";
import UploadOrderEntrySummary from "../UploadOrderEntrySummary";
import UploadNewQuotesThisWeekModal from "../UploadNewQuotesThisWeekModal";
import UploadAccountReceivable from "../UploadAccountReceivable";
import { useNavigate } from "react-router-dom";

const USER_ROLE_EXECUTIVE = 1;

const Sidebar = () => {
  const navigate = useNavigate();
  const [authContext, setAuthContext] = useAuth();
  const [sidebarContext] = useSidebar();

  const [uploadEmployeeUtilizationModal, setUploadEmployeeUtilizationModal] =
    useState();
  const [uploadOrderEntrySummary, setUploadOrderEntrySummary] = useState();
  const [uploadQuotes, setUploadQuotes] = useState();
  const [uploadAccountReceivable, setuploadAccountReceivable] = useState();

  const logout = () => {
    authApi.logout();
    setAuthContext({ currentUser: null });
    navigate("/auth/sign-in");
  };

  return (
    <nav className={`sidebar ${!sidebarContext.isOpen ? "toggled" : ""}`}>
      <div className="sidebar-content py-2">
        <div className="sidebar-brand d-flex align-items-center">
          <img
            src={brand}
            className="rounded p-0 col-12 bg-primary"
            alt={"Talladega Casting"}
          />
        </div>
        <SideBarUser />
        <ul className="sidebar-nav">
          {adminRoutes
            .filter(
              (route) =>
                route.sidebar &&
                (!route.roles ||
                  route.roles?.indexOf(authContext.currentUser.userRoleId) > -1)
            )
            .map((route, index) =>
              route.entries ? (
                <SidebarMenu
                  key={index}
                  name={route.name}
                  icon={route.icon}
                  to={route.path}
                  entries={route.entries}
                />
              ) : (
                <SidebarItem
                  key={index}
                  name={route.name}
                  icon={route.icon}
                  to={route.path}
                />
              )
            )}
          {authContext.currentUser.userRoleId === USER_ROLE_EXECUTIVE ? (
            <SidebarMenu
              name="Imports"
              icon={faUpload}
              entries={[
                {
                  icon: faUpload,
                  name: "Upload Employee Utilization CSV",
                  action: () => setUploadEmployeeUtilizationModal(true),
                  sidebar: true,
                },
                {
                  icon: faUpload,
                  name: "Order Entry Summary",
                  action: () => setUploadOrderEntrySummary(true),
                  sidebar: true,
                },
                {
                  icon: faUpload,
                  name: "Open Quotes",
                  action: () => setUploadQuotes(true),
                  sidebar: true,
                },
                {
                  icon: faUpload,
                  name: "Accounts Receivable",
                  action: () => setuploadAccountReceivable(true),
                  sidebar: true,
                },
              ]}
            />
          ) : null}
          <SidebarItem name="Logout" icon={faSignOutAlt} action={logout} />
        </ul>
      </div>
      {uploadEmployeeUtilizationModal && (
        <UploadEmployeeUtilizationModal
          onClose={() => setUploadEmployeeUtilizationModal(false)}
        />
      )}
      {uploadOrderEntrySummary && (
        <UploadOrderEntrySummary
          onClose={() => setUploadOrderEntrySummary(false)}
        />
      )}
      {uploadQuotes && (
        <UploadNewQuotesThisWeekModal
          onClose={() => setUploadQuotes(false)}
        />
      )}
      {uploadAccountReceivable && (
        <UploadAccountReceivable
          onClose={() => setuploadAccountReceivable(false)}
        />
      )}
    </nav>
  );
};

export default Sidebar;
